import { navigateToBookingsServicePage } from '@wix/bookings-catalog-calendar-viewer-navigation';
import { CreateActionParams } from '../actions';
import { BookingDTO, MemberAreaClickActions } from '../../types';
import { bookingsLoginUserAccountSeeAllServicesClick } from '@wix/bi-logger-wixboost-ugc/v2';

export type ClickOnViewMoreDetails = (args: { booking: BookingDTO }) => void;

export function createClickOnViewMoreDetailsAction({
  getControllerState,
  context,
}: CreateActionParams): ClickOnViewMoreDetails {
  return async ({ booking }) => {
    const { flowAPI, biLogger } = context;
    const [state] = getControllerState();
    const { wixCodeApi } = flowAPI.controllerConfig;
    const slug = booking.service?.mainSlug?.name;

    biLogger?.report(
      bookingsLoginUserAccountSeeAllServicesClick({
        bookingId: booking.bookingId,
        action: MemberAreaClickActions.ViewMoreDetails,
        extraInfo: JSON.stringify({
          serviceId: booking.service?.id,
        }),
        referralInfo: state.selectedTab.type,
      }),
    );

    try {
      if (slug) {
        await navigateToBookingsServicePage(wixCodeApi, {
          serviceSlug: slug,
        });
      } else {
        throw new Error('Service slug is missing');
      }
    } catch {
      wixCodeApi.location.to?.('/');
    }
  };
}
