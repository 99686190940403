import { CreateActionParams } from '../actions';
import { BookingsGroup } from '../../types';

export type GetPolicySnapshotActionProps = (args: {
  group: BookingsGroup;
}) => void;

export function createGetPolicySnapshotAction({
  getControllerState,
}: CreateActionParams): GetPolicySnapshotActionProps {
  return async ({ group }) => {
    const [state, setState] = getControllerState();
    const snapshotId = group.groupId || group.bookings[0]?.bookingId;

    // TODO: Replace with snapshot api
    const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));
    await delay(2000);

    setState({
      bookingsPolicySnapshots: {
        ...state.bookingsPolicySnapshots,
        [snapshotId]: [
          {
            policyId: '1234',
            serviceNames: 'Hair Styling, highlights',
            policyDescription:
              "Clients can reschedule or cancel hair appointments at no charge with at least 24 hours' notice. However, cancellations made within 24 hours of the scheduled appointment time may incur a cancellation fee equivalent to 50% of the service cost. No-shows without prior notice will be subject to a fee equivalent to the full service cost.",
          },
          {
            policyId: '2345',
            serviceNames: 'Manicure',
            policyDescription:
              "For nail appointments, clients are encouraged to provide 48 hours' notice for any cancellations or rescheduling. Cancellations within 24 to 48 hours may be subject to a fee of 30% of the service cost, while cancellations within 24 hours or no-shows may result in a fee of 50% of the service cost.",
          },
        ],
      },
    });
  };
}
