import { BookingsGroup } from '../../types';
import { CreateActionParams } from '../actions';
import { getGroupCancellationFeeDetails } from '../../utils/bookInfo';

export type GetListBookingFees = ({ group }: { group: BookingsGroup }) => void;

export function createGetListBookingFeesAction({
  context,
  getControllerState,
}: CreateActionParams): GetListBookingFees {
  return async ({ group }) => {
    const [state, setState] = getControllerState();
    const { businessInfo } = state;
    const { api } = context;
    const groupFeeId = group?.groupId || group?.bookings[0]?.bookingId;
    const bookingIdsWithFees = group.bookings
      .filter((book) => book.cancellationFeeId)
      ?.map((booking) => booking.bookingId);

    if (!bookingIdsWithFees?.length) {
      return;
    }

    const ListBookingsFees = await api.getListBookingFees({
      bookingIds: bookingIdsWithFees,
    });

    const listOfMoney =
      ListBookingsFees?.bookingFees?.map((fee) => fee.amount!) || [];

    const totalMoney = getGroupCancellationFeeDetails(
      listOfMoney,
      businessInfo?.dateRegionalSettingsLocale || '',
    )!;

    setState({
      groupTotalFees: {
        ...state.groupTotalFees,
        [groupFeeId]: {
          amount: totalMoney,
        },
      },
    });
  };
}
